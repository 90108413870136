import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from "isomorphic-dompurify";
import { AppFieldsEnum, AppVersionComparisonEnums, ContainerCPUValuesEnum, ContainerMemoryValuesEnum, ContainerPersistentMemoryValuesEnum, CreationWizardEnum, DefaultIconData, PermissionsEnum } from "../../components/enums";
import { ColumnLayout, GvtCard, GvtCardContent, GvtCardHeader, GvtCheckbox, GvtModalDialog, TypographyEnum } from "@gravity/ui-components";
import { StyledColumn, StyledColumnLayout, StyledReview, StyledReviewIcon } from "../../components/styled-components";
import { Icon, IconsEnum } from "@gravity/icons";
import PermissionsTable from "../../components/permissions-table/permissions-table";
import ZigbeeConfigTable from "../../components/zigbee-config-table/zigbee-config-table";
import SubscriptionsTable from "../../components/subscriptions-table/subscriptions-table";
import EnvironmentVariablesTable from "../../components/environment-variables-table/environment-variables-table";
import DescriptorTable from "../../components/usb-devices-table/descriptor-table";
import InterfacesConfigTable from "../../components/usb-devices-table/interfaces-config-table";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AccordionHeader from "../../components/accordion-header/accordion-header";
import OutboundFirewallPermissionsTable from "../../components/outball-firewall-permissions-table/outbound-firewall-permissions-table";

const AppVersionComparisonModal = (props) => {
    const { t } = useTranslation(['apps', 'common', 'appCreation']);
    const PanelEnum = {
        usb_left: 'panelusbleft',
        usb_right: 'panelusbright'
    };
    const [expandedLeft, setExpandedLeft] = useState(PanelEnum.usb_left + 0);
    const [expandedRight, setExpandedRight] = useState(PanelEnum.usb_right + 0);

    const rteContentParser = (input) => {
        return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(input)}} />
    }

    const booleanToString = (input) => {
        if(input) {
          return 'True';
        }
        return 'False';
    }

    const translateCategorie = (categorie_name, t) => {
        let key = 'categories.' + categorie_name.toLowerCase().split(" ").join("_")
        let translation = t(key, {ns: "common"})
        return key == translation || translation.startsWith('##') ? categorie_name : translation;
    }
    
    const arraysHaveSameElements = (arr1, arr2) => {
        const set1 = new Set(arr1);
        const set2 = new Set(arr2);
    
        if(set1.size !== set2.size) return false;
        for(let value of set1) {
          if(!set2.has(value)) return false;
        }
    
        return true;
    }
    
    const deepCompareObjects = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
    
        if (keys1.length !== keys2.length) return false;
    
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
    
            const areObjects = typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null;
            const areArrays = Array.isArray(val1) && Array.isArray(val2);
    
            if (areArrays) {
                if (val1.length !== val2.length) return false;
                const sorted1 = [...val1].sort(); 
                const sorted2 = [...val2].sort();
                for (let i = 0; i < sorted1.length; i++) {
                    if (!deepCompareObjects(sorted1[i], sorted2[i])) return false;
                }
            } else if (areObjects && !deepCompareObjects(val1, val2)) {
                return false;
            } else if (!areObjects && !areArrays && val1 !== val2) {
                return false;
            }
        }
    
        return true;
    };
    
    const arraysHaveSameObjects = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
    
        const unmatched2 = [...arr2];
        for (const obj1 of arr1) {
            let foundMatch = false;
            for (let i = 0; i < unmatched2.length; i++) {
                if (deepCompareObjects(obj1, unmatched2[i])) {
                    unmatched2.splice(i, 1);
                    foundMatch = true;
                    break;
                }
            }
            if (!foundMatch) return false;
        }
        return true;
    };
    
    const compareUsbInnerValues = (val1, val2) => {
        if(Array.isArray(val1) && Array.isArray(val2)) {
          return !(val1.length === val2.length && val1.every((el, idx) => !compareUsbInnerValues(el, val2[idx])));
        } else if(typeof val1 === 'object' && typeof val2 === 'object' && val1 !== null && val2 !== null) {
          return !Object.keys(val1).every((key) => !compareUsbInnerValues(val1[key], val2[key]));
        } 
        return val1 !== val2;
    }
    
    const compareUsbConfig = (item1, usbConfig) => {
        const item2 = usbConfig.find((item) => item.name === item1.name);
        if(!item2) {
          return {
            isMissing: true
          };
        }
    
        const changes = Object.keys(item1).reduce((acc, key) => {
          acc[key] = compareUsbInnerValues(item1[key], item2[key]);
          return acc;
        }, { isMissing: false });
    
        return changes;
    }
    
    const handleLeftPannelChange = (panel) => (event, isExpanded) => {
        setExpandedLeft(isExpanded ? panel : false);
    }
    
    const handleRightPannelChange = (panel) => (event, isExpanded) => {
        setExpandedRight(isExpanded ? panel : false);
    }
    
    const sdkList = ["1"];

    const [versionOneDetails, setVersionOneDetails] = useState({
        [AppVersionComparisonEnums.version]: '',
        [AppVersionComparisonEnums.is_live]: false,
        [AppVersionComparisonEnums.version_status]: '',
        [AppVersionComparisonEnums.created_by]: '',
        [AppVersionComparisonEnums.modified_by]: '',
        [AppFieldsEnum.name]: '',
        [AppFieldsEnum.icon]: DefaultIconData,
        [AppFieldsEnum.summary]: '',
        [AppFieldsEnum.description]: '',
        [AppFieldsEnum.release_notes]: '',
        [AppFieldsEnum.app_support]: '',
        [AppFieldsEnum.platform_dependencies]: '',
        [AppFieldsEnum.installation_instructions]: '',
        [AppFieldsEnum.author_website]: '',
        [AppFieldsEnum.platform_url]: '',
        [AppFieldsEnum.privacy_policy_url]: '',
        [AppFieldsEnum.license_agreement_url]: '',
        [AppFieldsEnum.categories]: [],
        [AppFieldsEnum.supported_platforms]: [],
        [AppFieldsEnum.minimum_sdk_version]: sdkList[0],
        [AppFieldsEnum.app_features]: [],
        [AppVersionComparisonEnums.is_beta]: null,
        [AppVersionComparisonEnums.last_gaversion]: '',
        [AppVersionComparisonEnums.beta_customer_list]: [],
        [AppFieldsEnum.feature_ble]: false,
        [AppFieldsEnum.lua_subscription_list]: [],
        [AppFieldsEnum.lua_script]: {},
        [AppFieldsEnum.feature_zigbee]: false,
        [AppFieldsEnum.feature_usb]: false,
        [AppFieldsEnum.feature_edge_compute]: false,
        [AppFieldsEnum.zigbee_config]: [],
        [AppFieldsEnum.usb_config]: [],
        [AppFieldsEnum.container_image_uuid]: '',
        [AppFieldsEnum.container_ap_image_uuid]: '',
        [AppFieldsEnum.container_cpu_ap]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_mini_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_small_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_medium_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_persistent_volume]: ContainerPersistentMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_ap]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_mini_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_small_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_medium_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_subscription_list]: [],
        [AppFieldsEnum.allowed_external_urls]: [],
        [AppFieldsEnum.app_ap_permission]: PermissionsEnum.block,
        [AppFieldsEnum.app_radio_permission]: PermissionsEnum.block,
        [AppFieldsEnum.app_beacon_permission]: PermissionsEnum.block,
        [AppFieldsEnum.required_user_permission]: false,
        [AppFieldsEnum.required_user_subscription]: false,
        [AppFieldsEnum.required_user_outbound_firewall_permission]: false,
        [AppFieldsEnum.required_user_ca_certificates]: false,
        [AppFieldsEnum.container_required_environment_variables]: [],
        [AppFieldsEnum.app_device_class_permissions]: [],
        [AppFieldsEnum.app_allowed_device_class_classification]: []
    });

    const [versionTwoDetails, setVersionTwoDetails] = useState({
        [AppVersionComparisonEnums.version]: '',
        [AppVersionComparisonEnums.is_live]: false,
        [AppVersionComparisonEnums.version_status]: '',
        [AppVersionComparisonEnums.created_by]: '',
        [AppVersionComparisonEnums.modified_by]: '',
        [AppFieldsEnum.name]: '',
        [AppFieldsEnum.icon]: DefaultIconData,
        [AppFieldsEnum.summary]: '',
        [AppFieldsEnum.description]: '',
        [AppFieldsEnum.release_notes]: '',
        [AppFieldsEnum.app_support]: '',
        [AppFieldsEnum.platform_dependencies]: '',
        [AppFieldsEnum.installation_instructions]: '',
        [AppFieldsEnum.author_website]: '',
        [AppFieldsEnum.platform_url]: '',
        [AppFieldsEnum.privacy_policy_url]: '',
        [AppFieldsEnum.license_agreement_url]: '',
        [AppFieldsEnum.categories]: [],
        [AppFieldsEnum.supported_platforms]: [],
        [AppFieldsEnum.minimum_sdk_version]: sdkList[0],
        [AppFieldsEnum.app_features]: [],
        [AppVersionComparisonEnums.is_beta]: null,
        [AppVersionComparisonEnums.last_gaversion]: '',
        [AppVersionComparisonEnums.beta_customer_list]: [],
        [AppFieldsEnum.feature_ble]: false,
        [AppFieldsEnum.lua_subscription_list]: [],
        [AppFieldsEnum.lua_script]: {},
        [AppFieldsEnum.feature_zigbee]: false,
        [AppFieldsEnum.feature_usb]: false,
        [AppFieldsEnum.feature_edge_compute]: false,
        [AppFieldsEnum.zigbee_config]: [],
        [AppFieldsEnum.usb_config]: [],
        [AppFieldsEnum.container_image_uuid]: '',
        [AppFieldsEnum.container_ap_image_uuid]: '',
        [AppFieldsEnum.container_cpu_ap]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_mini_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_small_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_cpu_medium_dc]: ContainerCPUValuesEnum.min.toString(),
        [AppFieldsEnum.container_persistent_volume]: ContainerPersistentMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_ap]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_mini_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_small_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_mem_medium_dc]: ContainerMemoryValuesEnum.min.toString(),
        [AppFieldsEnum.container_subscription_list]: [],
        [AppFieldsEnum.allowed_external_urls]: [],
        [AppFieldsEnum.app_ap_permission]: PermissionsEnum.block,
        [AppFieldsEnum.app_radio_permission]: PermissionsEnum.block,
        [AppFieldsEnum.app_beacon_permission]: PermissionsEnum.block,
        [AppFieldsEnum.required_user_permission]: false,
        [AppFieldsEnum.required_user_subscription]: false,
        [AppFieldsEnum.required_user_outbound_firewall_permission]: false,
        [AppFieldsEnum.required_user_ca_certificates]: false,
        [AppFieldsEnum.container_required_environment_variables]: [],
        [AppFieldsEnum.app_device_class_permissions]: [],
        [AppFieldsEnum.app_allowed_device_class_classification]: []
    });

    useEffect(() => {
        const data = props?.versionData;
        const versionOneData = data[0];
        const versionTwoData = data[1];
        console.log(data);

        console.log(versionOneData);
        console.log(versionTwoData);

        setVersionOneDetails(prevDetails => ({
            ...prevDetails,
            ...versionOneDetails,
            [AppVersionComparisonEnums.version]: versionOneData?.version,
            [AppVersionComparisonEnums.is_live]: versionOneData?.is_live,
            [AppVersionComparisonEnums.version_status]: versionOneData?.status,
            [AppVersionComparisonEnums.created_by]: versionOneData?.created_by,
            [AppVersionComparisonEnums.modified_by]: versionOneData?.modified_by,
            [AppFieldsEnum.name]: versionOneData?.name,
            [AppFieldsEnum.icon]: versionOneData?.icon,
            [AppFieldsEnum.summary]: versionOneData?.summary,
            [AppFieldsEnum.description]: versionOneData?.description,
            [AppFieldsEnum.release_notes]: versionOneData?.release_notes,
            [AppFieldsEnum.app_support]: versionOneData?.app_support,
            [AppFieldsEnum.platform_dependencies]: versionOneData?.platform_dependencies,
            [AppFieldsEnum.installation_instructions]: versionOneData?.installation_instructions,
            [AppFieldsEnum.author_website]: versionOneData?.author_website,
            [AppFieldsEnum.platform_url]: versionOneData?.platform_url,
            [AppFieldsEnum.privacy_policy_url]: versionOneData?.privacy_policy_url,
            [AppFieldsEnum.license_agreement_url]: versionOneData?.license_agreement_url,
            [AppFieldsEnum.categories]: versionOneData?.categories,
            [AppFieldsEnum.supported_platforms]: versionOneData?.supported_platforms,
            [AppFieldsEnum.minimum_sdk_version]: versionOneData?.minimum_sdk_version,
            [AppFieldsEnum.app_features]: versionOneData?.app_features,
            [AppVersionComparisonEnums.is_beta]: versionOneData?.attributes.is_beta,
            [AppVersionComparisonEnums.last_gaversion]: versionOneData?.attributes.last_gaversion,
            [AppVersionComparisonEnums.beta_customer_list]: versionOneData?.attributes.beta_customer_list,
            [AppFieldsEnum.feature_ble]: versionOneData?.feature_ble,
            [AppFieldsEnum.lua_subscription_list]: versionOneData?.lua_subscription_list,
            [AppFieldsEnum.lua_script]: versionOneData?.lua_script,
            [AppFieldsEnum.feature_zigbee]: versionOneData?.feature_zigbee,
            [AppFieldsEnum.feature_usb]: versionOneData?.feature_usb,
            [AppFieldsEnum.feature_edge_compute]: versionOneData?.feature_edge_compute,
            [AppFieldsEnum.zigbee_config]: versionOneData?.zigbee_config,
            [AppFieldsEnum.usb_config]: versionOneData?.usb_config,
            [AppFieldsEnum.container_image_uuid]: versionOneData?.container_image_uuid,
            [AppFieldsEnum.container_ap_image_uuid]: versionOneData?.container_ap_image_uuid,
            [AppFieldsEnum.container_cpu_ap]: versionOneData?.container_cpu_ap,
            [AppFieldsEnum.container_cpu_mini_dc]: versionOneData?.container_cpu_mini_dc,
            [AppFieldsEnum.container_cpu_small_dc]: versionOneData?.container_cpu_small_dc,
            [AppFieldsEnum.container_cpu_medium_dc]: versionOneData?.container_cpu_medium_dc,
            [AppFieldsEnum.container_persistent_volume]: versionOneData?.container_persistent_volume,
            [AppFieldsEnum.container_mem_ap]: versionOneData?.container_mem_ap,
            [AppFieldsEnum.container_mem_mini_dc]: versionOneData?.container_mem_mini_dc,
            [AppFieldsEnum.container_mem_small_dc]: versionOneData?.container_mem_small_dc,
            [AppFieldsEnum.container_mem_medium_dc]: versionOneData?.container_mem_medium_dc,
            [AppFieldsEnum.container_subscription_list]: versionOneData?.container_subscription_list,
            [AppFieldsEnum.allowed_external_urls]: versionOneData?.allowed_external_urls,
            [AppFieldsEnum.app_ap_permission]: versionOneData?.app_ap_permission,
            [AppFieldsEnum.app_radio_permission]: versionOneData?.app_radio_permission,
            [AppFieldsEnum.app_beacon_permission]: versionOneData?.app_beacon_permission,
            [AppFieldsEnum.required_user_permission]: versionOneData?.required_user_permission,
            [AppFieldsEnum.required_user_subscription]: versionOneData?.required_user_subscription,
            [AppFieldsEnum.required_user_outbound_firewall_permission]: versionOneData?.required_user_outbound_firewall_permission,
            [AppFieldsEnum.required_user_ca_certificates]: versionOneData?.required_user_ca_certificates,
            [AppFieldsEnum.container_required_environment_variables]: versionOneData?.container_required_environment_variables,
            [AppFieldsEnum.app_device_class_permissions]: versionOneData?.app_device_class_permissions,
            [AppFieldsEnum.app_allowed_device_class_classification]: versionOneData?.app_allowed_device_class_classification
        }));

        setVersionTwoDetails(prevDetails => ({
            ...prevDetails,
            ...versionTwoDetails,
            [AppVersionComparisonEnums.version]: versionTwoData?.version,
            [AppVersionComparisonEnums.is_live]: versionTwoData?.is_live,
            [AppVersionComparisonEnums.version_status]: versionTwoData?.status,
            [AppVersionComparisonEnums.created_by]: versionTwoData?.created_by,
            [AppVersionComparisonEnums.modified_by]: versionTwoData?.modified_by,
            [AppFieldsEnum.name]: versionTwoData?.name,
            [AppFieldsEnum.icon]: versionTwoData?.icon,
            [AppFieldsEnum.summary]: versionTwoData?.summary,
            [AppFieldsEnum.description]: versionTwoData?.description,
            [AppFieldsEnum.release_notes]: versionTwoData?.release_notes,
            [AppFieldsEnum.app_support]: versionTwoData?.app_support,
            [AppFieldsEnum.platform_dependencies]: versionTwoData?.platform_dependencies,
            [AppFieldsEnum.installation_instructions]: versionTwoData?.installation_instructions,
            [AppFieldsEnum.author_website]: versionTwoData?.author_website,
            [AppFieldsEnum.platform_url]: versionTwoData?.platform_url,
            [AppFieldsEnum.privacy_policy_url]: versionTwoData?.privacy_policy_url,
            [AppFieldsEnum.license_agreement_url]: versionTwoData?.license_agreement_url,
            [AppFieldsEnum.categories]: versionTwoData?.categories,
            [AppVersionComparisonEnums.is_beta]: versionOneData?.attributes.is_beta,
            [AppVersionComparisonEnums.last_gaversion]: versionTwoData?.attributes.last_gaversion,
            [AppVersionComparisonEnums.beta_customer_list]: versionTwoData?.attributes.beta_customer_list,
            [AppFieldsEnum.supported_platforms]: versionTwoData?.supported_platforms,
            [AppFieldsEnum.minimum_sdk_version]: versionTwoData?.minimum_sdk_version,
            [AppFieldsEnum.app_features]: versionTwoData?.app_features,
            [AppFieldsEnum.feature_ble]: versionTwoData?.feature_ble,
            [AppFieldsEnum.lua_subscription_list]: versionTwoData?.lua_subscription_list,
            [AppFieldsEnum.lua_script]: versionTwoData?.lua_script,
            [AppFieldsEnum.feature_zigbee]: versionTwoData?.feature_zigbee,
            [AppFieldsEnum.feature_usb]: versionTwoData?.feature_usb,
            [AppFieldsEnum.feature_edge_compute]: versionTwoData?.feature_edge_compute,
            [AppFieldsEnum.zigbee_config]: versionTwoData?.zigbee_config,
            [AppFieldsEnum.usb_config]: versionTwoData?.usb_config,
            [AppFieldsEnum.container_image_uuid]: versionTwoData?.container_image_uuid,
            [AppFieldsEnum.container_ap_image_uuid]: versionTwoData?.container_ap_image_uuid,
            [AppFieldsEnum.container_cpu_ap]: versionTwoData?.container_cpu_ap,
            [AppFieldsEnum.container_cpu_mini_dc]: versionTwoData?.container_cpu_mini_dc,
            [AppFieldsEnum.container_cpu_small_dc]: versionTwoData?.container_cpu_small_dc,
            [AppFieldsEnum.container_cpu_medium_dc]: versionTwoData?.container_cpu_medium_dc,
            [AppFieldsEnum.container_persistent_volume]: versionTwoData?.container_persistent_volume,
            [AppFieldsEnum.container_mem_ap]: versionTwoData?.container_mem_ap,
            [AppFieldsEnum.container_mem_mini_dc]: versionTwoData?.container_mem_mini_dc,
            [AppFieldsEnum.container_mem_small_dc]: versionTwoData?.container_mem_small_dc,
            [AppFieldsEnum.container_mem_medium_dc]: versionTwoData?.container_mem_medium_dc,
            [AppFieldsEnum.container_subscription_list]: versionTwoData?.container_subscription_list,
            [AppFieldsEnum.allowed_external_urls]: versionTwoData?.allowed_external_urls,
            [AppFieldsEnum.app_ap_permission]: versionTwoData?.app_ap_permission,
            [AppFieldsEnum.app_radio_permission]: versionTwoData?.app_radio_permission,
            [AppFieldsEnum.app_beacon_permission]: versionTwoData?.app_beacon_permission,
            [AppFieldsEnum.required_user_permission]: versionTwoData?.required_user_permission,
            [AppFieldsEnum.required_user_subscription]: versionTwoData?.required_user_subscription,
            [AppFieldsEnum.required_user_outbound_firewall_permission]: versionTwoData?.required_user_outbound_firewall_permission,
            [AppFieldsEnum.required_user_ca_certificates]: versionTwoData?.required_user_ca_certificates,
            [AppFieldsEnum.container_required_environment_variables]: versionTwoData?.container_required_environment_variables,
            [AppFieldsEnum.app_device_class_permissions]: versionTwoData?.app_device_class_permissions,
            [AppFieldsEnum.app_allowed_device_class_classification]: versionTwoData?.app_allowed_device_class_classification
        }));
    }, [props?.versionData])

    if(!props.openCompareVersions) {
        return null;
    }

    return (
        <GvtModalDialog
            open={props.openCompareVersions}
            onClose={() => {
                    props.setOpenCompareVersions(false);
                    props.setVersionData({});
                }
            } 
            title={t('app_versions.compare', { ns: 'apps' })}
            description={t('app_versions.compare_versions_description', { ns: 'apps' })}
            fullWidth={true}
            maxWidth={"xlg"}
        >
            <>
                <StyledColumn style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2%', alignItems: 'baseline'}}>
                    <GvtCard style={{ width: '50%' }}>
                        <GvtCardContent>
                            {versionOneDetails[AppFieldsEnum.name] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.app_details', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppVersionComparisonEnums.version] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.version] !== versionTwoDetails[AppVersionComparisonEnums.version] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.version', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppVersionComparisonEnums.version]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppVersionComparisonEnums.is_live] !== null && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.is_live] !== versionTwoDetails[AppVersionComparisonEnums.is_live] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.is_live', { ns: 'appCreation' })}</div>
                                                <div>{booleanToString(versionOneDetails[AppVersionComparisonEnums.is_live])}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.icon]?.url && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.icon]?.url !== versionTwoDetails[AppVersionComparisonEnums.icon]?.url ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.icon.label', { ns: 'appCreation' })}</div>
                                                <StyledReviewIcon>
                                                    <img src={versionOneDetails[AppFieldsEnum.icon]?.url ? versionOneDetails[AppFieldsEnum.icon]?.url : versionOneDetails[AppFieldsEnum.icon]?.data} />
                                                </StyledReviewIcon>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.name] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.name.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.name]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.summary] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.summary] !== versionTwoDetails[AppFieldsEnum.summary] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.summary.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.summary]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.description] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.description] !== versionTwoDetails[AppFieldsEnum.description] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.description.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionOneDetails[AppFieldsEnum.description])}
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.release_notes] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.release_notes] !== versionTwoDetails[AppFieldsEnum.release_notes] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.release_notes.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionOneDetails[AppFieldsEnum.release_notes])}
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.app_support] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.app_support] !== versionTwoDetails[AppFieldsEnum.app_support] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_support.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.app_support]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.platform_dependencies] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.platform_dependencies] !== versionTwoDetails[AppFieldsEnum.platform_dependencies] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.platform_dependencies.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionOneDetails[AppFieldsEnum.platform_dependencies])}
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.installation_instructions] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.installation_instructions] !== versionTwoDetails[AppFieldsEnum.installation_instructions] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.installation_instructions.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionOneDetails[AppFieldsEnum.installation_instructions])}
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppVersionComparisonEnums.version_status] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.version_status] !== versionTwoDetails[AppVersionComparisonEnums.version_status] ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.version_status', { ns: 'appCreation' })}</div>
                                                <div>{AppVersionComparisonEnums[versionOneDetails[AppVersionComparisonEnums.version_status]]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppVersionComparisonEnums.created_by] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.created_by] !== versionTwoDetails[AppVersionComparisonEnums.created_by] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.created_by', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppVersionComparisonEnums.created_by]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppVersionComparisonEnums.modified_by] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppVersionComparisonEnums.modified_by] !== versionTwoDetails[AppVersionComparisonEnums.modified_by] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.modified_by', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppVersionComparisonEnums.modified_by]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.author_website] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.author_website] !== versionTwoDetails[AppFieldsEnum.author_website] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.author_website.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.author_website]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.platform_url] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.platform_url] !== versionTwoDetails[AppFieldsEnum.platform_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.platform_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.platform_url]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.privacy_policy_url] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.privacy_policy_url] !== versionTwoDetails[AppFieldsEnum.privacy_policy_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.privacy_policy_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.privacy_policy_url]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.license_agreement_url] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.license_agreement_url] !== versionTwoDetails[AppFieldsEnum.license_agreement_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.license_agreement_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.license_agreement_url]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.categories].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.categories], versionTwoDetails[AppFieldsEnum.categories], 'id') ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.categories.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.categories].map(val => translateCategorie(val.name, t)).join(', ')}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.supported_platforms].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (!arraysHaveSameElements(versionOneDetails[AppFieldsEnum.supported_platforms], versionTwoDetails[AppFieldsEnum.supported_platforms]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.supported_platforms.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.supported_platforms].map(platform => AppVersionComparisonEnums[platform]).join(', ')}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.minimum_sdk_version] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.name] !== versionTwoDetails[AppFieldsEnum.name] ? null : (versionOneDetails[AppFieldsEnum.minimum_sdk_version] !== versionTwoDetails[AppFieldsEnum.minimum_sdk_version] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.minimum_sdk_version.label', { ns: 'appCreation' })}</div>
                                                <div>{'Version ' + versionOneDetails[AppFieldsEnum.minimum_sdk_version]}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionOneDetails[AppVersionComparisonEnums.is_beta] !== null && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppVersionComparisonEnums.is_beta] !== versionTwoDetails[AppVersionComparisonEnums.is_beta] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('versions.app_attributes', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppVersionComparisonEnums.is_beta] !== null && (<StyledReview style={{
                                                backgroundColor: null }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.is_beta', { ns: 'appCreation' })}</div>
                                                <div>{booleanToString(versionOneDetails[AppVersionComparisonEnums.is_beta])}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppVersionComparisonEnums.last_gaversion] > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppVersionComparisonEnums.is_beta] !== versionTwoDetails[AppVersionComparisonEnums.is_beta] ? null : (versionOneDetails[AppVersionComparisonEnums.last_gaversion] !== versionTwoDetails[AppVersionComparisonEnums.last_gaversion] ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.last_gaversion', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppVersionComparisonEnums.last_gaversion]}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppVersionComparisonEnums.beta_customer_list]?.length > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppVersionComparisonEnums.is_beta] !== versionTwoDetails[AppVersionComparisonEnums.is_beta] ? null : (!arraysHaveSameElements(versionOneDetails[AppVersionComparisonEnums.beta_customer_list], versionTwoDetails[AppVersionComparisonEnums.beta_customer_list]) ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.beta_customer_list', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppVersionComparisonEnums.beta_customer_list].join(', ')}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionOneDetails[AppFieldsEnum.feature_ble] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppFieldsEnum.feature_ble] !== versionTwoDetails[AppFieldsEnum.feature_ble] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_ble', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                        {versionOneDetails[AppFieldsEnum.lua_subscription_list].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_ble] !== versionTwoDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.lua_subscription_list], versionTwoDetails[AppFieldsEnum.lua_subscription_list], 'match_type') ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.subscriptions.label', { ns: 'appCreation' })}</div>
                                            <SubscriptionsTable subscriptions={versionOneDetails[AppFieldsEnum.lua_subscription_list]} readOnly={true} subscriptionFor={CreationWizardEnum.ble} disableEdit={true} /> 
                                        </StyledReview>)}
                                        {versionOneDetails[AppFieldsEnum.lua_script]?.data && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_ble] !== versionTwoDetails[AppFieldsEnum.feature_ble] ? null : (versionOneDetails[AppFieldsEnum.lua_script]?.data !== versionTwoDetails[AppFieldsEnum.lua_script]?.data ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.lua_script.label', { ns: 'appCreation' })}</div>
                                            <div>{atob(versionOneDetails[AppFieldsEnum.lua_script]?.data)}</div>
                                        </StyledReview>)}
                                        {versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_ble] !== versionTwoDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameElements(versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification], versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_allowed_device_class_classification.label', { ns: 'appCreation' })}</div>
                                            <div>{versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification].join(', ')}</div>
                                        </StyledReview>)}
                                        {versionOneDetails[AppFieldsEnum.app_device_class_permissions].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_ble] !== versionTwoDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.app_device_class_permissions], versionTwoDetails[AppFieldsEnum.app_device_class_permissions]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.permissions.label', { ns: 'appCreation' })}</div>
                                            <PermissionsTable permissions={versionOneDetails[AppFieldsEnum.app_device_class_permissions]} permissionFor={CreationWizardEnum.ble} disableEdit={true} />
                                        </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionOneDetails[AppFieldsEnum.feature_zigbee] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppFieldsEnum.feature_zigbee] !== versionTwoDetails[AppFieldsEnum.feature_zigbee] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_zigbee', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                        {versionOneDetails[AppFieldsEnum.zigbee_config].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_zigbee] !== versionTwoDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.zigbee_config], versionTwoDetails[AppFieldsEnum.zigbee_config], 'config_type') ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.panels.zigbee_config', { ns: 'appCreation' })}</div>
                                            <ZigbeeConfigTable zigbeeConfig={versionOneDetails[AppFieldsEnum.zigbee_config]} disableEdit={true} />
                                        </StyledReview>)}
                                        {versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_zigbee] !== versionTwoDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameElements(versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification], versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_allowed_device_class_classification.label', { ns: 'appCreation' })}</div>
                                            <div>{versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification].join(', ')}</div>
                                        </StyledReview>)}
                                        {versionOneDetails[AppFieldsEnum.app_device_class_permissions].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionOneDetails[AppFieldsEnum.feature_zigbee] !== versionTwoDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.app_device_class_permissions], versionTwoDetails[AppFieldsEnum.app_device_class_permissions], 'device_class') ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.permissions.label', { ns: 'appCreation' })}</div>
                                            <PermissionsTable permissions={versionOneDetails[AppFieldsEnum.app_device_class_permissions]} permissionFor={CreationWizardEnum.zigbee} disableEdit={true} />
                                        </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionOneDetails[AppFieldsEnum.feature_usb] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppFieldsEnum.feature_usb] !== versionTwoDetails[AppFieldsEnum.feature_usb] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_usb', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                    {versionOneDetails[AppFieldsEnum.usb_config].length > 0 && (<StyledReview style={{ backgroundColor: 'inherit' }}>
                                        <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.label', { ns: 'appCreation' })}</div>
                                        {versionOneDetails[AppFieldsEnum.usb_config].map((item, index) => {
                                            const changes = versionTwoDetails[AppFieldsEnum.feature_usb] ? compareUsbConfig(item, versionTwoDetails[AppFieldsEnum.usb_config]) : null;
                                            const highlightAccordionHeader = changes && (changes.device_class || changes.serial_data || changes.descriptors || changes.interfaces);
                                            return(
                                                <Accordion style={{marginLeft: "1.5%", backgroundColor: changes?.isMissing ? '#ffbc4451' : 'inherit'}} expanded={expandedLeft === (PanelEnum.usb_left + index)} onChange={handleLeftPannelChange(PanelEnum.usb_left + index)}>
                                                    <AccordionSummary style={{ backgroundColor: highlightAccordionHeader ? '#ffbc4451' : null }} expandIcon={<Icon name={IconsEnum.NavigationDown} />}>
                                                        <AccordionHeader title={item.name} error={false} />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <ColumnLayout layout='3'>
                                                            <StyledReview style={{ backgroundColor: 'inherit' }}>
                                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.name.label', { ns: 'appCreation' })}</div>
                                                                {item.name}
                                                            </StyledReview>
                                                            <StyledReview style={{ backgroundColor: changes?.device_class ? '#ffbc4451' : 'inherit' }}>
                                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.device_class.label', { ns: 'appCreation' })}</div>
                                                                {item.device_class}
                                                            </StyledReview>
                                                            <StyledReview style={{ backgroundColor: changes?.serial_data ? '#ffbc4451' : 'inherit' }}>
                                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.serial_data.label', { ns: 'appCreation' })}</div>
                                                                {booleanToString(item.serial_data)}
                                                            </StyledReview>
                                                        </ColumnLayout>
                                                        {item.descriptors.length > 0 && (<ColumnLayout layout='1'>
                                                            <StyledReview style={{marginTop: "2%", backgroundColor: changes?.descriptors ? '#ffbc4451' : 'inherit' }}>
                                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.descriptors.label', { ns: 'appCreation' })}</div>
                                                                <DescriptorTable descriptors={item.descriptors} disableEdit={true} />
                                                            </StyledReview>
                                                        </ColumnLayout>)}
                                                        {item.interfaces.length > 0 && (<ColumnLayout layout='1'>
                                                            <StyledReview style={{marginTop: "2%", backgroundColor: changes?.interfaces ? '#ffbc4451' : 'inherit' }}>
                                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.interfaces.label', { ns: 'appCreation' })}</div>
                                                                <InterfacesConfigTable interfaces={item.interfaces} serialData={item.serial_data} readOnly={true} disableEdit={true} />
                                                            </StyledReview>
                                                            </ColumnLayout>)}
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionOneDetails[AppFieldsEnum.feature_edge_compute] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_edge_compute', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppFieldsEnum.container_image_uuid] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_image_uuid] !== versionTwoDetails[AppFieldsEnum.container_image_uuid] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_image_url.label', { ns: 'appCreation' })}</div>
                                                <div style={{wordBreak: "break-word"}}>{versionOneDetails[AppFieldsEnum.container_image_uuid]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_cpu_mini_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_cpu_mini_dc] !== versionTwoDetails[AppFieldsEnum.container_cpu_mini_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_mini_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.container_cpu_mini_dc]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_cpu_small_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_cpu_small_dc] !== versionTwoDetails[AppFieldsEnum.container_cpu_small_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_small_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.container_cpu_small_dc]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_cpu_medium_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_cpu_medium_dc] !== versionTwoDetails[AppFieldsEnum.container_cpu_medium_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_medium_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.container_cpu_medium_dc]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_persistent_volume] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_persistent_volume] !== versionTwoDetails[AppFieldsEnum.container_persistent_volume] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_persistent_volume.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.container_persistent_volume]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_mem_mini_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_mem_mini_dc] !== versionTwoDetails[AppFieldsEnum.container_mem_mini_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_mini_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionOneDetails[AppFieldsEnum.container_mem_mini_dc] })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_mem_small_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_mem_small_dc] !== versionTwoDetails[AppFieldsEnum.container_mem_small_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_small_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionOneDetails[AppFieldsEnum.container_mem_small_dc] })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_mem_medium_dc] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_mem_medium_dc] !== versionTwoDetails[AppFieldsEnum.container_mem_medium_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_medium_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionOneDetails[AppFieldsEnum.container_mem_medium_dc] })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_ap_image_uuid] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_ap_image_uuid] !== versionTwoDetails[AppFieldsEnum.container_ap_image_uuid] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_ap_image_url.label', { ns: 'appCreation' })}</div>
                                                <div style={{wordBreak: "break-word"}}>{versionOneDetails[AppFieldsEnum.container_ap_image_uuid]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_cpu_ap] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_cpu_ap] !== versionTwoDetails[AppFieldsEnum.container_cpu_ap] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_ap.label', { ns: 'appCreation' })}</div>
                                                <div>{versionOneDetails[AppFieldsEnum.container_cpu_ap]}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.container_mem_ap] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.container_mem_ap] !== versionTwoDetails[AppFieldsEnum.container_mem_ap] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_ap.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionOneDetails[AppFieldsEnum.container_mem_ap] })}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionOneDetails[AppFieldsEnum.container_subscription_list].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.container_subscription_list], versionTwoDetails[AppFieldsEnum.container_subscription_list], 'match_type') ? '#ffbc4451' : null )}}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.subscriptions.label', { ns: 'appCreation' })}</div>
                                                <SubscriptionsTable subscriptions={versionOneDetails[AppFieldsEnum.container_subscription_list]} subscriptionFor={CreationWizardEnum.edge_compute} disableEdit={true} />
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.allowed_external_urls].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameElements(versionOneDetails[AppFieldsEnum.allowed_external_urls], versionTwoDetails[AppFieldsEnum.allowed_external_urls]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.allowed_external_urls.label', { ns: 'appCreation' })}</div>
                                                <OutboundFirewallPermissionsTable permissions={versionOneDetails[AppFieldsEnum.allowed_external_urls]} disableEdit={true} />
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.app_ap_permission] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.app_ap_permission] !== versionTwoDetails[AppFieldsEnum.app_ap_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_ap_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionOneDetails[AppFieldsEnum.app_ap_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.app_radio_permission] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.app_radio_permission] !== versionTwoDetails[AppFieldsEnum.app_radio_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_radio_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionOneDetails[AppFieldsEnum.app_radio_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.app_beacon_permission] && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.app_beacon_permission] !== versionTwoDetails[AppFieldsEnum.app_beacon_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_beacon_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionOneDetails[AppFieldsEnum.app_beacon_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.required_user_permission] !== null && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.required_user_permission] !== versionTwoDetails[AppFieldsEnum.required_user_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_permission.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionOneDetails[AppFieldsEnum.required_user_permission]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.required_user_subscription] !== null && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.required_user_subscription] !== versionTwoDetails[AppFieldsEnum.required_user_subscription] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_subscription.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionOneDetails[AppFieldsEnum.required_user_subscription]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.required_user_outbound_firewall_permission] !== null && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.required_user_outbound_firewall_permission] !== versionTwoDetails[AppFieldsEnum.required_user_outbound_firewall_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_outbound_firewall_permission.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionOneDetails[AppFieldsEnum.required_user_outbound_firewall_permission]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionOneDetails[AppFieldsEnum.required_user_ca_certificates] !== null && (<StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionOneDetails[AppFieldsEnum.required_user_ca_certificates] !== versionTwoDetails[AppFieldsEnum.required_user_ca_certificates] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_ca_certificates.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionOneDetails[AppFieldsEnum.required_user_ca_certificates]} readOnly={true} />
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <ColumnLayout layout="1">
                                        {versionOneDetails[AppFieldsEnum.container_required_environment_variables].length > 0 && (<div>
                                            <br />
                                            <StyledReview style={{
                                                backgroundColor: versionOneDetails[AppFieldsEnum.feature_edge_compute] !== versionTwoDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameObjects(versionOneDetails[AppFieldsEnum.container_required_environment_variables], versionTwoDetails[AppFieldsEnum.container_required_environment_variables], 'name') ? '#ffbc4451' : null), width: "100%" }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_required_environment_variables.label', { ns: 'appCreation' })}</div>
                                                <EnvironmentVariablesTable envVars={versionOneDetails[AppFieldsEnum.container_required_environment_variables]} disableEdit={true} />
                                            </StyledReview>
                                        </div>)}
                                    </ColumnLayout>
                                </StyledColumnLayout>
                            )}
                        </GvtCardContent>
                    </GvtCard>

                    <GvtCard style={{ marginLeft: '2%', width: '50%' }}>
                        <GvtCardContent>
                            {versionTwoDetails[AppFieldsEnum.name] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.app_details', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppVersionComparisonEnums.version] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.version] !== versionOneDetails[AppVersionComparisonEnums.version] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.version', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppVersionComparisonEnums.version]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppVersionComparisonEnums.is_live] !== null && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.is_live] !== versionOneDetails[AppVersionComparisonEnums.is_live] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.is_live', { ns: 'appCreation' })}</div>
                                                <div>{booleanToString(versionTwoDetails[AppVersionComparisonEnums.is_live])}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.icon]?.url && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.icon]?.url !== versionOneDetails[AppVersionComparisonEnums.icon]?.url ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.icon.label', { ns: 'appCreation' })}</div>
                                                <StyledReviewIcon>
                                                    <img src={versionTwoDetails[AppFieldsEnum.icon]?.url ? versionTwoDetails[AppFieldsEnum.icon]?.url : versionTwoDetails[AppFieldsEnum.icon]?.data} />
                                                </StyledReviewIcon>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.name] && (<StyledReview style={{
                                                backgroundColor: null }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.name.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.name]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.summary] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.summary] !== versionOneDetails[AppFieldsEnum.summary] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.summary.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.summary]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.description] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.description] !== versionOneDetails[AppFieldsEnum.description] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.description.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionTwoDetails[AppFieldsEnum.description])}
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.release_notes] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.release_notes] !== versionOneDetails[AppFieldsEnum.release_notes] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.release_notes.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionTwoDetails[AppFieldsEnum.release_notes])}
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.app_support] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.app_support] !== versionOneDetails[AppFieldsEnum.app_support] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_support.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.app_support]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.platform_dependencies] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.platform_dependencies] !== versionOneDetails[AppFieldsEnum.platform_dependencies] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.platform_dependencies.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionTwoDetails[AppFieldsEnum.platform_dependencies])}
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.installation_instructions] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.installation_instructions] !== versionOneDetails[AppFieldsEnum.installation_instructions] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.installation_instructions.label', { ns: 'appCreation' })}</div>
                                                {rteContentParser(versionTwoDetails[AppFieldsEnum.installation_instructions])}
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppVersionComparisonEnums.version_status] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.version_status] !== versionOneDetails[AppVersionComparisonEnums.version_status] ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.version_status', { ns: 'appCreation' })}</div>
                                                <div>{AppVersionComparisonEnums[versionTwoDetails[AppVersionComparisonEnums.version_status]]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppVersionComparisonEnums.created_by] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.created_by] !== versionOneDetails[AppVersionComparisonEnums.created_by] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.created_by', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppVersionComparisonEnums.created_by]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppVersionComparisonEnums.modified_by] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppVersionComparisonEnums.modified_by] !== versionOneDetails[AppVersionComparisonEnums.modified_by] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.modified_by', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppVersionComparisonEnums.modified_by]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.author_website] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.author_website] !== versionOneDetails[AppFieldsEnum.author_website] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.author_website.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.author_website]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.platform_url] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.platform_url] !== versionOneDetails[AppFieldsEnum.platform_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.platform_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.platform_url]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.privacy_policy_url] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.privacy_policy_url] !== versionOneDetails[AppFieldsEnum.privacy_policy_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.privacy_policy_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.privacy_policy_url]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.license_agreement_url] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.license_agreement_url] !== versionOneDetails[AppFieldsEnum.license_agreement_url] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.license_agreement_url.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.license_agreement_url]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.categories].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.categories], versionOneDetails[AppFieldsEnum.categories]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.categories.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.categories].map(val => translateCategorie(val.name, t)).join(', ')}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.supported_platforms].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (!arraysHaveSameElements(versionTwoDetails[AppFieldsEnum.supported_platforms], versionOneDetails[AppFieldsEnum.supported_platforms]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.supported_platforms.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.supported_platforms].map(platform => AppVersionComparisonEnums[platform]).join(', ')}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.minimum_sdk_version] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.name] !== versionOneDetails[AppFieldsEnum.name] ? null : (versionTwoDetails[AppFieldsEnum.minimum_sdk_version] !== versionOneDetails[AppFieldsEnum.minimum_sdk_version] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.minimum_sdk_version.label', { ns: 'appCreation' })}</div>
                                                <div>{'Version ' + versionTwoDetails[AppFieldsEnum.minimum_sdk_version]}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionTwoDetails[AppVersionComparisonEnums.is_beta] !== null && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppVersionComparisonEnums.is_beta] !== versionOneDetails[AppVersionComparisonEnums.is_beta] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('versions.app_attributes', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppVersionComparisonEnums.is_beta] !== null && (<StyledReview style={{
                                                backgroundColor: null }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.is_beta', { ns: 'appCreation' })}</div>
                                                <div>{booleanToString(versionTwoDetails[AppVersionComparisonEnums.is_beta])}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppVersionComparisonEnums.last_gaversion] > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppVersionComparisonEnums.is_beta] !== versionOneDetails[AppVersionComparisonEnums.is_beta] ? null : (versionTwoDetails[AppVersionComparisonEnums.last_gaversion] !== versionOneDetails[AppVersionComparisonEnums.last_gaversion] ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.last_gaversion', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppVersionComparisonEnums.last_gaversion]}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppVersionComparisonEnums.beta_customer_list]?.length > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppVersionComparisonEnums.is_beta] !== versionOneDetails[AppVersionComparisonEnums.is_beta] ? null : (!arraysHaveSameElements(versionTwoDetails[AppVersionComparisonEnums.beta_customer_list], versionOneDetails[AppVersionComparisonEnums.beta_customer_list]) ? '#ffbc4451' : null) }}> 
                                                <div className={TypographyEnum.TextBoldSmall}>{t('versions.attributes.beta_customer_list', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppVersionComparisonEnums.beta_customer_list].join(', ')}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionTwoDetails[AppFieldsEnum.feature_ble] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppFieldsEnum.feature_ble] !== versionOneDetails[AppFieldsEnum.feature_ble] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_ble', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                        {versionTwoDetails[AppFieldsEnum.lua_subscription_list].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_ble] !== versionOneDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.lua_subscription_list], versionOneDetails[AppFieldsEnum.lua_subscription_list]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.subscriptions.label', { ns: 'appCreation' })}</div>
                                            <SubscriptionsTable subscriptions={versionTwoDetails[AppFieldsEnum.lua_subscription_list]} readOnly={true} subscriptionFor={CreationWizardEnum.ble} disableEdit={true} /> 
                                        </StyledReview>)}
                                        {versionTwoDetails[AppFieldsEnum.lua_script]?.data && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_ble] !== versionOneDetails[AppFieldsEnum.feature_ble] ? null : (versionTwoDetails[AppFieldsEnum.lua_script]?.data !== versionOneDetails[AppFieldsEnum.lua_script]?.data ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.lua_script.label', { ns: 'appCreation' })}</div>
                                            <div>{atob(versionTwoDetails[AppFieldsEnum.lua_script]?.data)}</div>
                                        </StyledReview>)}
                                        {versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_ble] !== versionOneDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameElements(versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification], versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_allowed_device_class_classification.label', { ns: 'appCreation' })}</div>
                                            <div>{versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification].join(', ')}</div>
                                        </StyledReview>)}
                                        {versionTwoDetails[AppFieldsEnum.app_device_class_permissions].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_ble] !== versionOneDetails[AppFieldsEnum.feature_ble] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.app_device_class_permissions], versionOneDetails[AppFieldsEnum.app_device_class_permissions]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.permissions.label', { ns: 'appCreation' })}</div>
                                            <PermissionsTable permissions={versionTwoDetails[AppFieldsEnum.app_device_class_permissions]} permissionFor={CreationWizardEnum.ble} disableEdit={true} />
                                        </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionTwoDetails[AppFieldsEnum.feature_zigbee] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppFieldsEnum.feature_zigbee] !== versionOneDetails[AppFieldsEnum.feature_zigbee] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_zigbee', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                        {versionTwoDetails[AppFieldsEnum.zigbee_config].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_zigbee] !== versionOneDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.zigbee_config], versionOneDetails[AppFieldsEnum.zigbee_config]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.panels.zigbee_config', { ns: 'appCreation' })}</div>
                                            <ZigbeeConfigTable zigbeeConfig={versionTwoDetails[AppFieldsEnum.zigbee_config]} disableEdit={true} />
                                        </StyledReview>)}
                                        {versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_zigbee] !== versionOneDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameElements(versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification], versionOneDetails[AppFieldsEnum.app_allowed_device_class_classification]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_allowed_device_class_classification.label', { ns: 'appCreation' })}</div>
                                            <div>{versionTwoDetails[AppFieldsEnum.app_allowed_device_class_classification].join(', ')}</div>
                                        </StyledReview>)}
                                        {versionTwoDetails[AppFieldsEnum.app_device_class_permissions].length > 0 && (<StyledReview style={{
                                            backgroundColor: versionTwoDetails[AppFieldsEnum.feature_zigbee] !== versionOneDetails[AppFieldsEnum.feature_zigbee] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.app_device_class_permissions], versionOneDetails[AppFieldsEnum.app_device_class_permissions]) ? '#ffbc4451' : null) }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.permissions.label', { ns: 'appCreation' })}</div>
                                            <PermissionsTable permissions={versionTwoDetails[AppFieldsEnum.app_device_class_permissions]} permissionFor={CreationWizardEnum.zigbee} disableEdit={true} />
                                        </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionTwoDetails[AppFieldsEnum.feature_usb] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppFieldsEnum.feature_usb] !== versionOneDetails[AppFieldsEnum.feature_usb] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_usb', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='1'>
                                        {versionTwoDetails[AppFieldsEnum.usb_config].length > 0 && (<StyledReview style={{ backgroundColor: 'inherit' }}>
                                            <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.label', { ns: 'appCreation' })}</div>
                                            {versionTwoDetails[AppFieldsEnum.usb_config].map((item, index) => {
                                                const changes = versionOneDetails[AppFieldsEnum.feature_usb] ? compareUsbConfig(item, versionOneDetails[AppFieldsEnum.usb_config]) : null;
                                                const highlightAccordionHeader = changes && (changes.device_class || changes.serial_data || changes.descriptors || changes.interfaces);
                                                return(
                                                    <Accordion style={{marginLeft: "1.5%", backgroundColor: changes?.isMissing ? '#ffbc4451' : 'inherit'}} expanded={expandedRight === (PanelEnum.usb_right + index)} onChange={handleRightPannelChange(PanelEnum.usb_right + index)}>
                                                        <AccordionSummary style={{ backgroundColor: highlightAccordionHeader ? '#ffbc4451' : null }} expandIcon={<Icon name={IconsEnum.NavigationDown} />}>
                                                            <AccordionHeader title={item.name} error={false} />
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ColumnLayout layout='3'>
                                                                <StyledReview style={{ backgroundColor: 'inherit' }}>
                                                                    <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.name.label', { ns: 'appCreation' })}</div>
                                                                    {item.name}
                                                                </StyledReview>
                                                                <StyledReview style={{ backgroundColor: changes?.device_class ? '#ffbc4451' : 'inherit' }}>
                                                                    <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.device_class.label', { ns: 'appCreation' })}</div>
                                                                    {item.device_class}
                                                                </StyledReview>
                                                                <StyledReview style={{ backgroundColor: changes?.serial_data ? '#ffbc4451' : 'inherit' }}>
                                                                    <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.serial_data.label', { ns: 'appCreation' })}</div>
                                                                    {booleanToString(item.serial_data)}
                                                                </StyledReview>
                                                                </ColumnLayout>
                                                                {item.descriptors.length > 0 && (<ColumnLayout layout='1'>
                                                                <StyledReview style={{marginTop: "2%", backgroundColor: changes?.descriptors ? '#ffbc4451' : 'inherit' }}>
                                                                    <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.descriptors.label', { ns: 'appCreation' })}</div>
                                                                    <DescriptorTable descriptors={item.descriptors} disableEdit={true} />
                                                                </StyledReview>
                                                                </ColumnLayout>)}
                                                                {item.interfaces.length > 0 && (<ColumnLayout layout='1'>
                                                                <StyledReview style={{marginTop: "2%", backgroundColor: changes?.interfaces ? '#ffbc4451' : 'inherit' }}>
                                                                    <div className={TypographyEnum.TextBoldSmall}>{t('fields.usb_config.fields.interfaces.label', { ns: 'appCreation' })}</div>
                                                                    <InterfacesConfigTable interfaces={item.interfaces} serialData={item.serial_data} readOnly={true} disableEdit={true} />
                                                                </StyledReview>
                                                                </ColumnLayout>)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })}
                                        </StyledReview>)}
                                    </ColumnLayout>
                                    <br />
                                </StyledColumnLayout>
                            )}

                            {versionTwoDetails[AppFieldsEnum.feature_edge_compute] && (
                                <StyledColumnLayout style={{
                                    backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? '#ffbc4451' : null }}>
                                    <GvtCardHeader title={t('steps.titles.feature_edge_compute', { ns: 'appCreation' })}></GvtCardHeader>
                                    <ColumnLayout layout='2'>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppFieldsEnum.container_image_uuid] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_image_uuid] !== versionOneDetails[AppFieldsEnum.container_image_uuid] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_image_url.label', { ns: 'appCreation' })}</div>
                                                <div style={{wordBreak: "break-word"}}>{versionTwoDetails[AppFieldsEnum.container_image_uuid]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_cpu_mini_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_cpu_mini_dc] !== versionOneDetails[AppFieldsEnum.container_cpu_mini_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_mini_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.container_cpu_mini_dc]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_cpu_small_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_cpu_small_dc] !== versionOneDetails[AppFieldsEnum.container_cpu_small_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_small_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.container_cpu_small_dc]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_cpu_medium_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_cpu_medium_dc] !== versionOneDetails[AppFieldsEnum.container_cpu_medium_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_medium_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.container_cpu_medium_dc]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_persistent_volume] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_persistent_volume] !== versionOneDetails[AppFieldsEnum.container_persistent_volume] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_persistent_volume.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.container_persistent_volume]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_mem_mini_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_mem_mini_dc] !== versionOneDetails[AppFieldsEnum.container_mem_mini_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_mini_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionTwoDetails[AppFieldsEnum.container_mem_mini_dc] })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_mem_small_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_mem_small_dc] !== versionOneDetails[AppFieldsEnum.container_mem_small_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_small_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionTwoDetails[AppFieldsEnum.container_mem_small_dc] })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_mem_medium_dc] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_mem_medium_dc] !== versionOneDetails[AppFieldsEnum.container_mem_medium_dc] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_medium_dc.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionTwoDetails[AppFieldsEnum.container_mem_medium_dc] })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_ap_image_uuid] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_ap_image_uuid] !== versionOneDetails[AppFieldsEnum.container_ap_image_uuid] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_ap_image_url.label', { ns: 'appCreation' })}</div>
                                                <div style={{wordBreak: "break-word"}}>{versionTwoDetails[AppFieldsEnum.container_ap_image_uuid]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_cpu_ap] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_cpu_ap] !== versionOneDetails[AppFieldsEnum.container_cpu_ap] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_cpu_ap.label', { ns: 'appCreation' })}</div>
                                                <div>{versionTwoDetails[AppFieldsEnum.container_cpu_ap]}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.container_mem_ap] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.container_mem_ap] !== versionOneDetails[AppFieldsEnum.container_mem_ap] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_mem_ap.label', { ns: 'appCreation' })}</div>
                                                <div>{t('memory', { ns: "common", memory: versionTwoDetails[AppFieldsEnum.container_mem_ap] })}</div>
                                            </StyledReview>)}
                                        </ColumnLayout>
                                        <ColumnLayout layout='1'>
                                            {versionTwoDetails[AppFieldsEnum.container_subscription_list].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.container_subscription_list], versionOneDetails[AppFieldsEnum.container_subscription_list]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.subscriptions.label', { ns: 'appCreation' })}</div>
                                                <SubscriptionsTable subscriptions={versionTwoDetails[AppFieldsEnum.container_subscription_list]} subscriptionFor={CreationWizardEnum.edge_compute} disableEdit={true} />
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.allowed_external_urls].length > 0 && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameElements(versionTwoDetails[AppFieldsEnum.allowed_external_urls], versionOneDetails[AppFieldsEnum.allowed_external_urls]) ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.allowed_external_urls.label', { ns: 'appCreation' })}</div>
                                                <OutboundFirewallPermissionsTable permissions={versionTwoDetails[AppFieldsEnum.allowed_external_urls]} disableEdit={true} />
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.app_ap_permission] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.app_ap_permission] !== versionOneDetails[AppFieldsEnum.app_ap_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_ap_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionTwoDetails[AppFieldsEnum.app_ap_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.app_radio_permission] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.app_radio_permission] !== versionOneDetails[AppFieldsEnum.app_radio_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_radio_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionTwoDetails[AppFieldsEnum.app_radio_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.app_beacon_permission] && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.app_beacon_permission] !== versionOneDetails[AppFieldsEnum.app_beacon_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.app_beacon_permission.label', { ns: 'appCreation' })}</div>
                                                <div>{t(versionTwoDetails[AppFieldsEnum.app_beacon_permission].toLowerCase(), { ns: "common" })}</div>
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.required_user_permission] !== null && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.required_user_permission] !== versionOneDetails[AppFieldsEnum.required_user_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_permission.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionTwoDetails[AppFieldsEnum.required_user_permission]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.required_user_subscription] !== null && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.required_user_subscription] !== versionOneDetails[AppFieldsEnum.required_user_subscription] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_subscription.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionTwoDetails[AppFieldsEnum.required_user_subscription]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.required_user_outbound_firewall_permission] !== null && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.required_user_outbound_firewall_permission] !== versionOneDetails[AppFieldsEnum.required_user_outbound_firewall_permission] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_outbound_firewall_permission.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionTwoDetails[AppFieldsEnum.required_user_outbound_firewall_permission]} readOnly={true} />
                                            </StyledReview>)}
                                            {versionTwoDetails[AppFieldsEnum.required_user_ca_certificates] !== null && (<StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (versionTwoDetails[AppFieldsEnum.required_user_ca_certificates] !== versionOneDetails[AppFieldsEnum.required_user_ca_certificates] ? '#ffbc4451' : null) }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.required_user_ca_certificates.label', { ns: 'appCreation' })}</div>
                                                <GvtCheckbox checked={versionTwoDetails[AppFieldsEnum.required_user_ca_certificates]} readOnly={true} />
                                            </StyledReview>)}
                                        </ColumnLayout>
                                    </ColumnLayout>
                                    <ColumnLayout layout="1">
                                        {versionTwoDetails[AppFieldsEnum.container_required_environment_variables].length > 0 && (<div>
                                            <br />
                                            <StyledReview style={{
                                                backgroundColor: versionTwoDetails[AppFieldsEnum.feature_edge_compute] !== versionOneDetails[AppFieldsEnum.feature_edge_compute] ? null : (!arraysHaveSameObjects(versionTwoDetails[AppFieldsEnum.container_required_environment_variables], versionOneDetails[AppFieldsEnum.container_required_environment_variables], 'name') ? '#ffbc4451' : null), width: "100%" }}>
                                                <div className={TypographyEnum.TextBoldSmall}>{t('fields.container_required_environment_variables.label', { ns: 'appCreation' })}</div>
                                                <EnvironmentVariablesTable envVars={versionTwoDetails[AppFieldsEnum.container_required_environment_variables]} disableEdit={true} />
                                            </StyledReview>
                                        </div>)}
                                    </ColumnLayout>
                                </StyledColumnLayout>
                            )}
                        </GvtCardContent>
                    </GvtCard>
                </StyledColumn>
            </>
        </GvtModalDialog>
    )
}

export default AppVersionComparisonModal;